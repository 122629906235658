export const COUNTRIES = [
    { "name": "Afghanistan", "code": "AF" },
    { "name": "Aland Islands", "code": "AX" },
    { "name": "Albania", "code": "AL" },
    { "name": "Algeria", "code": "DZ" },
    { "name": "American Samoa", "code": "AS" },
    { "name": "Andorra", "code": "AD" },
    { "name": "Angola", "code": "AO" },
    { "name": "Anguilla", "code": "AI" },
    { "name": "Antarctica", "code": "AQ" },
    { "name": "Antigua and Barbuda", "code": "AG" },
    { "name": "Argentina", "code": "AR" },
    { "name": "Armenia", "code": "AM" },
    { "name": "Aruba", "code": "AW" },
    { "name": "Australia", "code": "AU" },
    { "name": "Austria", "code": "AT" },
    { "name": "Azerbaijan", "code": "AZ" },
    { "name": "Bahamas", "code": "BS" },
    { "name": "Bahrain", "code": "BH" },
    { "name": "Bangladesh", "code": "BD" },
    { "name": "Barbados", "code": "BB" },
    { "name": "Belarus", "code": "BY" },
    { "name": "Belgium", "code": "BE" },
    { "name": "Belize", "code": "BZ" },
    { "name": "Benin", "code": "BJ" },
    { "name": "Bermuda", "code": "BM" },
    { "name": "Bhutan", "code": "BT" },
    { "name": "Bolivia", "code": "BO" },
    { "name": "Bosnia and Herzegovina", "code": "BA" },
    { "name": "Botswana", "code": "BW" },
    { "name": "Bouvet Island", "code": "BV" },
    { "name": "Brazil", "code": "BR" },
    { "name": "British Indian Ocean Territory", "code": "IO" },
    { "name": "Brunei Darussalam", "code": "BN" },
    { "name": "Bulgaria", "code": "BG" },
    { "name": "Burkina Faso", "code": "BF" },
    { "name": "Burundi", "code": "BI" },
    { "name": "Cambodia", "code": "KH" },
    { "name": "Cameroon", "code": "CM" },
    { "name": "Canada", "code": "CA" },
    { "name": "Cape Verde", "code": "CV" },
    { "name": "Cayman Islands", "code": "KY" },
    { "name": "Central African Republic", "code": "CF" },
    { "name": "Chad", "code": "TD" },
    { "name": "Chile", "code": "CL" },
    { "name": "China", "code": "CN" },
    { "name": "Christmas Island", "code": "CX" },
    { "name": "Cocos (Keeling) Islands", "code": "CC" },
    { "name": "Colombia", "code": "CO" },
    { "name": "Comoros", "code": "KM" },
    { "name": "Congo", "code": "CG" },
    { "name": "Congo, The Democratic Republic of the", "code": "CD" },
    { "name": "Cook Islands", "code": "CK" },
    { "name": "Costa Rica", "code": "CR" },
    {
        "name": "Cote D\"Ivoire", "code": "CI"
    },
    { "name": "Croatia", "code": "HR" },
    { "name": "Cuba", "code": "CU" },
    { "name": "Cyprus", "code": "CY" },
    { "name": "Czech Republic", "code": "CZ" },
    { "name": "Denmark", "code": "DK" },
    { "name": "Djibouti", "code": "DJ" },
    { "name": "Dominica", "code": "DM" },
    { "name": "Dominican Republic", "code": "DO" },
    { "name": "Ecuador", "code": "EC" },
    { "name": "Egypt", "code": "EG" },
    { "name": "El Salvador", "code": "SV" },
    { "name": "Equatorial Guinea", "code": "GQ" },
    { "name": "Eritrea", "code": "ER" },
    { "name": "Estonia", "code": "EE" },
    { "name": "Ethiopia", "code": "ET" },
    { "name": "Falkland Islands (Malvinas)", "code": "FK" },
    { "name": "Faroe Islands", "code": "FO" },
    { "name": "Fiji", "code": "FJ" },
    { "name": "Finland", "code": "FI" },
    { "name": "France", "code": "FR" },
    { "name": "French Guiana", "code": "GF" },
    { "name": "French Polynesia", "code": "PF" },
    { "name": "French Southern Territories", "code": "TF" },
    { "name": "Gabon", "code": "GA" },
    { "name": "Gambia", "code": "GM" },
    { "name": "Georgia", "code": "GE" },
    { "name": "Germany", "code": "DE" },
    { "name": "Ghana", "code": "GH" },
    { "name": "Gibraltar", "code": "GI" },
    { "name": "Greece", "code": "GR" },
    { "name": "Greenland", "code": "GL" },
    { "name": "Grenada", "code": "GD" },
    { "name": "Guadeloupe", "code": "GP" },
    { "name": "Guam", "code": "GU" },
    { "name": "Guatemala", "code": "GT" },
    { "name": "Guernsey", "code": "GG" },
    { "name": "Guinea", "code": "GN" },
    { "name": "Guinea-Bissau", "code": "GW" },
    { "name": "Guyana", "code": "GY" },
    { "name": "Haiti", "code": "HT" },
    { "name": "Heard Island and Mcdonald Islands", "code": "HM" },
    { "name": "Holy See (Vatican City State)", "code": "VA" },
    { "name": "Honduras", "code": "HN" },
    { "name": "Hong Kong", "code": "HK" },
    { "name": "Hungary", "code": "HU" },
    { "name": "Iceland", "code": "IS" },
    { "name": "India", "code": "IN" },
    { "name": "Indonesia", "code": "ID" },
    { "name": "Iran, Islamic Republic Of", "code": "IR" },
    { "name": "Iraq", "code": "IQ" },
    { "name": "Ireland", "code": "IE" },
    { "name": "Isle of Man", "code": "IM" },
    { "name": "Israel", "code": "IL" },
    { "name": "Italy", "code": "IT" },
    { "name": "Jamaica", "code": "JM" },
    { "name": "Japan", "code": "JP" },
    { "name": "Jersey", "code": "JE" },
    { "name": "Jordan", "code": "JO" },
    { "name": "Kazakhstan", "code": "KZ" },
    { "name": "Kenya", "code": "KE" },
    { "name": "Kiribati", "code": "KI" },
    {
        "name": "Korea, Democratic People\"S Republic of", "code": "KP"
    },
    { "name": "Korea, Republic of", "code": "KR" },
    { "name": "Kuwait", "code": "KW" },
    { "name": "Kyrgyzstan", "code": "KG" },
    {
        "name": "Lao People\"S Democratic Republic", "code": "LA"
    },
    { "name": "Latvia", "code": "LV" },
    { "name": "Lebanon", "code": "LB" },
    { "name": "Lesotho", "code": "LS" },
    { "name": "Liberia", "code": "LR" },
    { "name": "Libyan Arab Jamahiriya", "code": "LY" },
    { "name": "Liechtenstein", "code": "LI" },
    { "name": "Lithuania", "code": "LT" },
    { "name": "Luxembourg", "code": "LU" },
    { "name": "Macao", "code": "MO" },
    { "name": "Macedonia, The Former Yugoslav Republic of", "code": "MK" },
    { "name": "Madagascar", "code": "MG" },
    { "name": "Malawi", "code": "MW" },
    { "name": "Malaysia", "code": "MY" },
    { "name": "Maldives", "code": "MV" },
    { "name": "Mali", "code": "ML" },
    { "name": "Malta", "code": "MT" },
    { "name": "Marshall Islands", "code": "MH" },
    { "name": "Martinique", "code": "MQ" },
    { "name": "Mauritania", "code": "MR" },
    { "name": "Mauritius", "code": "MU" },
    { "name": "Mayotte", "code": "YT" },
    { "name": "Mexico", "code": "MX" },
    { "name": "Micronesia, Federated States of", "code": "FM" },
    { "name": "Moldova, Republic of", "code": "MD" },
    { "name": "Monaco", "code": "MC" },
    { "name": "Mongolia", "code": "MN" },
    { "name": "Montenegro", "code": "ME" },
    { "name": "Montserrat", "code": "MS" },
    { "name": "Morocco", "code": "MA" },
    { "name": "Mozambique", "code": "MZ" },
    { "name": "Myanmar", "code": "MM" },
    { "name": "Namibia", "code": "NA" },
    { "name": "Nauru", "code": "NR" },
    { "name": "Nepal", "code": "NP" },
    { "name": "Netherlands", "code": "NL" },
    { "name": "Netherlands Antilles", "code": "AN" },
    { "name": "New Caledonia", "code": "NC" },
    { "name": "New Zealand", "code": "NZ" },
    { "name": "Nicaragua", "code": "NI" },
    { "name": "Niger", "code": "NE" },
    { "name": "Nigeria", "code": "NG" },
    { "name": "Niue", "code": "NU" },
    { "name": "Norfolk Island", "code": "NF" },
    { "name": "Northern Mariana Islands", "code": "MP" },
    { "name": "Norway", "code": "NO" },
    { "name": "Oman", "code": "OM" },
    { "name": "Pakistan", "code": "PK" },
    { "name": "Palau", "code": "PW" },
    { "name": "Palestinian Territory, Occupied", "code": "PS" },
    { "name": "Panama", "code": "PA" },
    { "name": "Papua New Guinea", "code": "PG" },
    { "name": "Paraguay", "code": "PY" },
    { "name": "Peru", "code": "PE" },
    { "name": "Philippines", "code": "PH" },
    { "name": "Pitcairn", "code": "PN" },
    { "name": "Poland", "code": "PL" },
    { "name": "Portugal", "code": "PT" },
    { "name": "Puerto Rico", "code": "PR" },
    { "name": "Qatar", "code": "QA" },
    { "name": "Reunion", "code": "RE" },
    { "name": "Romania", "code": "RO" },
    { "name": "Russian Federation", "code": "RU" },
    { "name": "RWANDA", "code": "RW" },
    { "name": "Saint Helena", "code": "SH" },
    { "name": "Saint Kitts and Nevis", "code": "KN" },
    { "name": "Saint Lucia", "code": "LC" },
    { "name": "Saint Pierre and Miquelon", "code": "PM" },
    { "name": "Saint Vincent and the Grenadines", "code": "VC" },
    { "name": "Samoa", "code": "WS" },
    { "name": "San Marino", "code": "SM" },
    { "name": "Sao Tome and Principe", "code": "ST" },
    { "name": "Saudi Arabia", "code": "SA" },
    { "name": "Senegal", "code": "SN" },
    { "name": "Serbia", "code": "RS" },
    { "name": "Seychelles", "code": "SC" },
    { "name": "Sierra Leone", "code": "SL" },
    { "name": "Singapore", "code": "SG" },
    { "name": "Slovakia", "code": "SK" },
    { "name": "Slovenia", "code": "SI" },
    { "name": "Solomon Islands", "code": "SB" },
    { "name": "Somalia", "code": "SO" },
    { "name": "South Africa", "code": "ZA" },
    { "name": "South Georgia and the South Sandwich Islands", "code": "GS" },
    { "name": "Spain", "code": "ES" },
    { "name": "Sri Lanka", "code": "LK" },
    { "name": "Sudan", "code": "SD" },
    { "name": "Suriname", "code": "SR" },
    { "name": "Svalbard and Jan Mayen", "code": "SJ" },
    { "name": "Swaziland", "code": "SZ" },
    { "name": "Sweden", "code": "SE" },
    { "name": "Switzerland", "code": "CH" },
    { "name": "Syrian Arab Republic", "code": "SY" },
    { "name": "Taiwan, Province of China", "code": "TW" },
    { "name": "Tajikistan", "code": "TJ" },
    { "name": "Tanzania, United Republic of", "code": "TZ" },
    { "name": "Thailand", "code": "TH" },
    { "name": "Timor-Leste", "code": "TL" },
    { "name": "Togo", "code": "TG" },
    { "name": "Tokelau", "code": "TK" },
    { "name": "Tonga", "code": "TO" },
    { "name": "Trinidad and Tobago", "code": "TT" },
    { "name": "Tunisia", "code": "TN" },
    { "name": "Turkey", "code": "TR" },
    { "name": "Turkmenistan", "code": "TM" },
    { "name": "Turks and Caicos Islands", "code": "TC" },
    { "name": "Tuvalu", "code": "TV" },
    { "name": "Uganda", "code": "UG" },
    { "name": "Ukraine", "code": "UA" },
    { "name": "United Arab Emirates", "code": "AE" },
    { "name": "United Kingdom", "code": "GB" },
    { "name": "United States", "code": "US" },
    { "name": "United States Minor Outlying Islands", "code": "UM" },
    { "name": "Uruguay", "code": "UY" },
    { "name": "Uzbekistan", "code": "UZ" },
    { "name": "Vanuatu", "code": "VU" },
    { "name": "Venezuela", "code": "VE" },
    { "name": "Viet Nam", "code": "VN" },
    { "name": "Virgin Islands, British", "code": "VG" },
    { "name": "Virgin Islands, U.S.", "code": "VI" },
    { "name": "Wallis and Futuna", "code": "WF" },
    { "name": "Western Sahara", "code": "EH" },
    { "name": "Yemen", "code": "YE" },
    { "name": "Zambia", "code": "ZM" },
    { "name": "Zimbabwe", "code": "ZW" }
]

export const PROVINCES = [
    { "code": "NONE", "name": "None Selected (outside of Canada and United States)" },
    { "code": "BC", "name": "British Columbia" },
    { "code": "AB", "name": "Alberta" },
    { "code": "SK", "name": "Saskatchewan" },
    { "code": "MB", "name": "Manitoba" },
    { "code": "ON", "name": "Ontario" },
    { "code": "QC", "name": "Quebec" },
    { "code": "NB", "name": "New Brunswick" },
    { "code": "NS", "name": "Nova Scotia" },
    { "code": "PE", "name": "Prince Edward Island" },
    { "code": "NL", "name": "Newfoundland" },
    { "code": "NU", "name": "Nunavut" },
    { "code": "YT", "name": "Yukon Territory" },
    { "code": "NT", "name": "North West Territories" },
    { "code": "AL", "name": "Alabama" },
    { "code": "AK", "name": "Alaska" },
    { "code": "AS", "name": "American Samoa" },
    { "code": "AZ", "name": "Arizona" },
    { "code": "AR", "name": "Arkansas" },
    { "code": "CA", "name": "California" },
    { "code": "CO", "name": "Colorado" },
    { "code": "CT", "name": "Connecticut" },
    { "code": "DE", "name": "Delaware" },
    { "code": "DC", "name": "District of Columbia" },
    { "code": "FM", "name": "Fed. St. of Micronesia" },
    { "code": "FL", "name": "Florida" },
    { "code": "GA", "name": "Georgia" },
    { "code": "GU", "name": "Guam" },
    { "code": "HI", "name": "Hawaii" },
    { "code": "ID", "name": "Idaho" },
    { "code": "IL", "name": "Illinois" },
    { "code": "IN", "name": "Indiana" },
    { "code": "IA", "name": "Iowa" },
    { "code": "KS", "name": "Kansas" },
    { "code": "KY", "name": "Kentucky" },
    { "code": "LA", "name": "Louisiana" },
    { "code": "ME", "name": "Maine" },
    { "code": "MH", "name": "Marshall Islands" },
    { "code": "MD", "name": "Maryland" },
    { "code": "MA", "name": "Massachusetts" },
    { "code": "MI", "name": "Michigan" },
    { "code": "MN", "name": "Minnesota" },
    { "code": "MS", "name": "Mississippi" },
    { "code": "MO", "name": "Missouri" },
    { "code": "MT", "name": "Montana" },
    { "code": "NE", "name": "Nebraska" },
    { "code": "NV", "name": "Nevada" },
    { "code": "NH", "name": "New Hampshire" },
    { "code": "NJ", "name": "New Jersey" },
    { "code": "NM", "name": "New Mexico" },
    { "code": "NY", "name": "New York" },
    { "code": "NC", "name": "North Carolina" },
    { "code": "ND", "name": "North Dakota" },
    { "code": "MP", "name": "Northern Mariana Is." },
    { "code": "OH", "name": "Ohio" },
    { "code": "OK", "name": "Oklahoma" },
    { "code": "OR", "name": "Oregon" },
    { "code": "PW", "name": "Palau" },
    { "code": "PA", "name": "Pennsylvania" },
    { "code": "PR", "name": "Puerto Rico" },
    { "code": "RI", "name": "Rhode Island" },
    { "code": "SC", "name": "South Carolina" },
    { "code": "SD", "name": "South Dakota" },
    { "code": "TN", "name": "Tennessee" },
    { "code": "TX", "name": "Texas" },
    { "code": "UT", "name": "Utah" },
    { "code": "VT", "name": "Vermont" },
    { "code": "VA", "name": "Virginia" },
    { "code": "VI", "name": "Virgin Islands" },
    { "code": "WA", "name": "Washington" },
    { "code": "WV", "name": "West Virginia" },
    { "code": "WI", "name": "Wisconsin" },
    { "code": "WY", "name": "Wyoming" },
]

export const CURRENCY = [
    { "country": "AFGHANISTAN", "currency": "Afghani", "code": "AFN", "id": "971" },
    { "country": "ÅLAND ISLANDS", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "ALBANIA", "currency": "Lek", "code": "ALL", "id": "008" },
    { "country": "ALGERIA", "currency": "Algerian Dinar", "code": "DZD", "id": "012" },
    { "country": "AMERICAN SAMOA", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "ANDORRA", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "ANGOLA", "currency": "Kwanza", "code": "AOA", "id": "973" },
    { "country": "ANGUILLA", "currency": "East Caribbean Dollar", "code": "XCD", "id": "951" },
    { "country": "ANTIGUA AND BARBUDA", "currency": "East Caribbean Dollar", "code": "XCD", "id": "951" },
    { "country": "ARGENTINA", "currency": "Argentine Peso", "code": "ARS", "id": "032" },
    { "country": "ARMENIA", "currency": "Armenian Dram", "code": "AMD", "id": "051" },
    { "country": "ARUBA", "currency": "Aruban Florin", "code": "AWG", "id": "533" },
    { "country": "AUSTRALIA", "currency": "Australian Dollar", "code": "AUD", "id": "036" },
    { "country": "AUSTRIA", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "AZERBAIJAN", "currency": "Azerbaijan Manat", "code": "AZN", "id": "944" },
    { "country": "BAHAMAS (THE)", "currency": "Bahamian Dollar", "code": "BSD", "id": "044" },
    { "country": "BAHRAIN", "currency": "Bahraini Dinar", "code": "BHD", "id": "048" },
    { "country": "BANGLADESH", "currency": "Taka", "code": "BDT", "id": "050" },
    { "country": "BARBADOS", "currency": "Barbados Dollar", "code": "BBD", "id": "052" },
    { "country": "BELARUS", "currency": "Belarusian Ruble", "code": "BYN", "id": "933" },
    { "country": "BELGIUM", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "BELIZE", "currency": "Belize Dollar", "code": "BZD", "id": "084" },
    { "country": "BENIN", "currency": "CFA Franc BCEAO", "code": "XOF", "id": "952" },
    { "country": "BERMUDA", "currency": "Bermudian Dollar", "code": "BMD", "id": "060" },
    { "country": "BHUTAN", "currency": "Indian Rupee", "code": "INR", "id": "356" },
    { "country": "BHUTAN", "currency": "Ngultrum", "code": "BTN", "id": "064" },
    { "country": "BOLIVIA (PLURINATIONAL STATE OF)", "currency": "Boliviano", "code": "BOB", "id": "068" },
    { "country": "BOLIVIA (PLURINATIONAL STATE OF)", "currency": "Mvdol", "code": "BOV", "id": "984" },
    { "country": "BONAIRE, SINT EUSTATIUS AND SABA", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "BOSNIA AND HERZEGOVINA", "currency": "Convertible Mark", "code": "BAM", "id": "977" },
    { "country": "BOTSWANA", "currency": "Pula", "code": "BWP", "id": "072" },
    { "country": "BOUVET ISLAND", "currency": "Norwegian Krone", "code": "NOK", "id": "578" },
    { "country": "BRAZIL", "currency": "Brazilian Real", "code": "BRL", "id": "986" },
    { "country": "BRITISH INDIAN OCEAN TERRITORY (THE)", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "BRUNEI DARUSSALAM", "currency": "Brunei Dollar", "code": "BND", "id": "096" },
    { "country": "BULGARIA", "currency": "Bulgarian Lev", "code": "BGN", "id": "975" },
    { "country": "BURKINA FASO", "currency": "CFA Franc BCEAO", "code": "XOF", "id": "952" },
    { "country": "BURUNDI", "currency": "Burundi Franc", "code": "BIF", "id": "108" },
    { "country": "CABO VERDE", "currency": "Cabo Verde Escudo", "code": "CVE", "id": "132" },
    { "country": "CAMBODIA", "currency": "Riel", "code": "KHR", "id": "116" },
    { "country": "CAMEROON", "currency": "CFA Franc BEAC", "code": "XAF", "id": "950" },
    { "country": "CANADA", "currency": "Canadian Dollar", "code": "CAD", "id": "124" },
    { "country": "CAYMAN ISLANDS (THE)", "currency": "Cayman Islands Dollar", "code": "KYD", "id": "136" },
    { "country": "CENTRAL AFRICAN REPUBLIC (THE)", "currency": "CFA Franc BEAC", "code": "XAF", "id": "950" },
    { "country": "CHAD", "currency": "CFA Franc BEAC", "code": "XAF", "id": "950" },
    { "country": "CHILE", "currency": "Chilean Peso", "code": "CLP", "id": "152" },
    { "country": "CHILE", "currency": "Unidad de Fomento", "code": "CLF", "id": "990" },
    { "country": "CHINA", "currency": "Yuan Renminbi", "code": "CNY", "id": "156" },
    { "country": "CHRISTMAS ISLAND", "currency": "Australian Dollar", "code": "AUD", "id": "036" },
    { "country": "COCOS (KEELING) ISLANDS (THE)", "currency": "Australian Dollar", "code": "AUD", "id": "036" },
    { "country": "COLOMBIA", "currency": "Colombian Peso", "code": "COP", "id": "170" },
    { "country": "COLOMBIA", "currency": "Unidad de Valor Real", "code": "COU", "id": "970" },
    { "country": "COMOROS (THE)", "currency": "Comorian Franc ", "code": "KMF", "id": "174" },
    { "country": "CONGO (THE DEMOCRATIC REPUBLIC OF THE)", "currency": "Congolese Franc", "code": "CDF", "id": "976" },
    { "country": "CONGO (THE)", "currency": "CFA Franc BEAC", "code": "XAF", "id": "950" },
    { "country": "COOK ISLANDS (THE)", "currency": "New Zealand Dollar", "code": "NZD", "id": "554" },
    { "country": "COSTA RICA", "currency": "Costa Rican Colon", "code": "CRC", "id": "188" },
    { "country": "CÔTE D'IVOIRE", "currency": "CFA Franc BCEAO", "code": "XOF", "id": "952" },
    { "country": "CROATIA", "currency": "Kuna", "code": "HRK", "id": "191" },
    { "country": "CUBA", "currency": "Cuban Peso", "code": "CUP", "id": "192" },
    { "country": "CUBA", "currency": "Peso Convertible", "code": "CUC", "id": "931" },
    { "country": "CURAÇAO", "currency": "Netherlands Antillean Guilder", "code": "ANG", "id": "532" },
    { "country": "CYPRUS", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "CZECHIA", "currency": "Czech Koruna", "code": "CZK", "id": "203" },
    { "country": "DENMARK", "currency": "Danish Krone", "code": "DKK", "id": "208" },
    { "country": "DJIBOUTI", "currency": "Djibouti Franc", "code": "DJF", "id": "262" },
    { "country": "DOMINICA", "currency": "East Caribbean Dollar", "code": "XCD", "id": "951" },
    { "country": "DOMINICAN REPUBLIC (THE)", "currency": "Dominican Peso", "code": "DOP", "id": "214" },
    { "country": "ECUADOR", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "EGYPT", "currency": "Egyptian Pound", "code": "EGP", "id": "818" },
    { "country": "EL SALVADOR", "currency": "El Salvador Colon", "code": "SVC", "id": "222" },
    { "country": "EL SALVADOR", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "EQUATORIAL GUINEA", "currency": "CFA Franc BEAC", "code": "XAF", "id": "950" },
    { "country": "ERITREA", "currency": "Nakfa", "code": "ERN", "id": "232" },
    { "country": "ESTONIA", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "ETHIOPIA", "currency": "Ethiopian Birr", "code": "ETB", "id": "230" },
    { "country": "EUROPEAN UNION", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "FALKLAND ISLANDS (THE) [MALVINAS]", "currency": "Falkland Islands Pound", "code": "FKP", "id": "238" },
    { "country": "FAROE ISLANDS (THE)", "currency": "Danish Krone", "code": "DKK", "id": "208" },
    { "country": "FIJI", "currency": "Fiji Dollar", "code": "FJD", "id": "242" },
    { "country": "FINLAND", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "FRANCE", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "FRENCH GUIANA", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "FRENCH POLYNESIA", "currency": "CFP Franc", "code": "XPF", "id": "953" },
    { "country": "FRENCH SOUTHERN TERRITORIES (THE)", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "GABON", "currency": "CFA Franc BEAC", "code": "XAF", "id": "950" },
    { "country": "GAMBIA (THE)", "currency": "Dalasi", "code": "GMD", "id": "270" },
    { "country": "GEORGIA", "currency": "Lari", "code": "GEL", "id": "981" },
    { "country": "GERMANY", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "GHANA", "currency": "Ghana Cedi", "code": "GHS", "id": "936" },
    { "country": "GIBRALTAR", "currency": "Gibraltar Pound", "code": "GIP", "id": "292" },
    { "country": "GREECE", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "GREENLAND", "currency": "Danish Krone", "code": "DKK", "id": "208" },
    { "country": "GRENADA", "currency": "East Caribbean Dollar", "code": "XCD", "id": "951" },
    { "country": "GUADELOUPE", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "GUAM", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "GUATEMALA", "currency": "Quetzal", "code": "GTQ", "id": "320" },
    { "country": "GUERNSEY", "currency": "Pound Sterling", "code": "GBP", "id": "826" },
    { "country": "GUINEA", "currency": "Guinean Franc", "code": "GNF", "id": "324" },
    { "country": "GUINEA-BISSAU", "currency": "CFA Franc BCEAO", "code": "XOF", "id": "952" },
    { "country": "GUYANA", "currency": "Guyana Dollar", "code": "GYD", "id": "328" },
    { "country": "HAITI", "currency": "Gourde", "code": "HTG", "id": "332" },
    { "country": "HAITI", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "HEARD ISLAND AND McDONALD ISLANDS", "currency": "Australian Dollar", "code": "AUD", "id": "036" },
    { "country": "HOLY SEE (THE)", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "HONDURAS", "currency": "Lempira", "code": "HNL", "id": "340" },
    { "country": "HONG KONG", "currency": "Hong Kong Dollar", "code": "HKD", "id": "344" },
    { "country": "HUNGARY", "currency": "Forint", "code": "HUF", "id": "348" },
    { "country": "ICELAND", "currency": "Iceland Krona", "code": "ISK", "id": "352" },
    { "country": "INDIA", "currency": "Indian Rupee", "code": "INR", "id": "356" },
    { "country": "INDONESIA", "currency": "Rupiah", "code": "IDR", "id": "360" },
    { "country": "INTERNATIONAL MONETARY FUND (IMF)", "currency": "SDR (Special Drawing Right)", "code": "XDR", "id": "960" },
    { "country": "IRAN (ISLAMIC REPUBLIC OF)", "currency": "Iranian Rial", "code": "IRR", "id": "364" },
    { "country": "IRAQ", "currency": "Iraqi Dinar", "code": "IQD", "id": "368" },
    { "country": "IRELAND", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "ISLE OF MAN", "currency": "Pound Sterling", "code": "GBP", "id": "826" },
    { "country": "ISRAEL", "currency": "New Israeli Sheqel", "code": "ILS", "id": "376" },
    { "country": "ITALY", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "JAMAICA", "currency": "Jamaican Dollar", "code": "JMD", "id": "388" },
    { "country": "JAPAN", "currency": "Yen", "code": "JPY", "id": "392" },
    { "country": "JERSEY", "currency": "Pound Sterling", "code": "GBP", "id": "826" },
    { "country": "JORDAN", "currency": "Jordanian Dinar", "code": "JOD", "id": "400" },
    { "country": "KAZAKHSTAN", "currency": "Tenge", "code": "KZT", "id": "398" },
    { "country": "KENYA", "currency": "Kenyan Shilling", "code": "KES", "id": "404" },
    { "country": "KIRIBATI", "currency": "Australian Dollar", "code": "AUD", "id": "036" },
    { "country": "KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)", "currency": "North Korean Won", "code": "KPW", "id": "408" },
    { "country": "KOREA (THE REPUBLIC OF)", "currency": "Won", "code": "KRW", "id": "410" },
    { "country": "KUWAIT", "currency": "Kuwaiti Dinar", "code": "KWD", "id": "414" },
    { "country": "KYRGYZSTAN", "currency": "Som", "code": "KGS", "id": "417" },
    { "country": "LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)", "currency": "Lao Kip", "code": "LAK", "id": "418" },
    { "country": "LATVIA", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "LEBANON", "currency": "Lebanese Pound", "code": "LBP", "id": "422" },
    { "country": "LESOTHO", "currency": "Loti", "code": "LSL", "id": "426" },
    { "country": "LESOTHO", "currency": "Rand", "code": "ZAR", "id": "710" },
    { "country": "LIBERIA", "currency": "Liberian Dollar", "code": "LRD", "id": "430" },
    { "country": "LIBYA", "currency": "Libyan Dinar", "code": "LYD", "id": "434" },
    { "country": "LIECHTENSTEIN", "currency": "Swiss Franc", "code": "CHF", "id": "756" },
    { "country": "LITHUANIA", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "LUXEMBOURG", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "MACAO", "currency": "Pataca", "code": "MOP", "id": "446" },
    { "country": "MACEDONIA (THE FORMER YUGOSLAV REPUBLIC OF)", "currency": "Denar", "code": "MKD", "id": "807" },
    { "country": "MADAGASCAR", "currency": "Malagasy Ariary", "code": "MGA", "id": "969" },
    { "country": "MALAWI", "currency": "Malawi Kwacha", "code": "MWK", "id": "454" },
    { "country": "MALAYSIA", "currency": "Malaysian Ringgit", "code": "MYR", "id": "458" },
    { "country": "MALDIVES", "currency": "Rufiyaa", "code": "MVR", "id": "462" },
    { "country": "MALI", "currency": "CFA Franc BCEAO", "code": "XOF", "id": "952" },
    { "country": "MALTA", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "MARSHALL ISLANDS (THE)", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "MARTINIQUE", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "MAURITANIA", "currency": "Ouguiya", "code": "MRU", "id": "929" },
    { "country": "MAURITIUS", "currency": "Mauritius Rupee", "code": "MUR", "id": "480" },
    { "country": "MAYOTTE", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP", "currency": "ADB Unit of Account", "code": "XUA", "id": "965" },
    { "country": "MEXICO", "currency": "Mexican Peso", "code": "MXN", "id": "484" },
    { "country": "MEXICO", "currency": "Mexican Unidad de Inversion (UDI)", "code": "MXV", "id": "979" },
    { "country": "MICRONESIA (FEDERATED STATES OF)", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "MOLDOVA (THE REPUBLIC OF)", "currency": "Moldovan Leu", "code": "MDL", "id": "498" },
    { "country": "MONACO", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "MONGOLIA", "currency": "Tugrik", "code": "MNT", "id": "496" },
    { "country": "MONTENEGRO", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "MONTSERRAT", "currency": "East Caribbean Dollar", "code": "XCD", "id": "951" },
    { "country": "MOROCCO", "currency": "Moroccan Dirham", "code": "MAD", "id": "504" },
    { "country": "MOZAMBIQUE", "currency": "Mozambique Metical", "code": "MZN", "id": "943" },
    { "country": "MYANMAR", "currency": "Kyat", "code": "MMK", "id": "104" },
    { "country": "NAMIBIA", "currency": "Namibia Dollar", "code": "NAD", "id": "516" },
    { "country": "NAMIBIA", "currency": "Rand", "code": "ZAR", "id": "710" },
    { "country": "NAURU", "currency": "Australian Dollar", "code": "AUD", "id": "036" },
    { "country": "NEPAL", "currency": "Nepalese Rupee", "code": "NPR", "id": "524" },
    { "country": "NETHERLANDS (THE)", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "NEW CALEDONIA", "currency": "CFP Franc", "code": "XPF", "id": "953" },
    { "country": "NEW ZEALAND", "currency": "New Zealand Dollar", "code": "NZD", "id": "554" },
    { "country": "NICARAGUA", "currency": "Cordoba Oro", "code": "NIO", "id": "558" },
    { "country": "NIGER (THE)", "currency": "CFA Franc BCEAO", "code": "XOF", "id": "952" },
    { "country": "NIGERIA", "currency": "Naira", "code": "NGN", "id": "566" },
    { "country": "NIUE", "currency": "New Zealand Dollar", "code": "NZD", "id": "554" },
    { "country": "NORFOLK ISLAND", "currency": "Australian Dollar", "code": "AUD", "id": "036" },
    { "country": "NORTHERN MARIANA ISLANDS (THE)", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "NORWAY", "currency": "Norwegian Krone", "code": "NOK", "id": "578" },
    { "country": "OMAN", "currency": "Rial Omani", "code": "OMR", "id": "512" },
    { "country": "PAKISTAN", "currency": "Pakistan Rupee", "code": "PKR", "id": "586" },
    { "country": "PALAU", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "PANAMA", "currency": "Balboa", "code": "PAB", "id": "590" },
    { "country": "PANAMA", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "PAPUA NEW GUINEA", "currency": "Kina", "code": "PGK", "id": "598" },
    { "country": "PARAGUAY", "currency": "Guarani", "code": "PYG", "id": "600" },
    { "country": "PERU", "currency": "Sol", "code": "PEN", "id": "604" },
    { "country": "PHILIPPINES (THE)", "currency": "Philippine Peso", "code": "PHP", "id": "608" },
    { "country": "PITCAIRN", "currency": "New Zealand Dollar", "code": "NZD", "id": "554" },
    { "country": "POLAND", "currency": "Zloty", "code": "PLN", "id": "985" },
    { "country": "PORTUGAL", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "PUERTO RICO", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "QATAR", "currency": "Qatari Rial", "code": "QAR", "id": "634" },
    { "country": "RÉUNION", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "ROMANIA", "currency": "Romanian Leu", "code": "RON", "id": "946" },
    { "country": "RUSSIAN FEDERATION (THE)", "currency": "Russian Ruble", "code": "RUB", "id": "643" },
    { "country": "RWANDA", "currency": "Rwanda Franc", "code": "RWF", "id": "646" },
    { "country": "SAINT BARTHÉLEMY", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA", "currency": "Saint Helena Pound", "code": "SHP", "id": "654" },
    { "country": "SAINT KITTS AND NEVIS", "currency": "East Caribbean Dollar", "code": "XCD", "id": "951" },
    { "country": "SAINT LUCIA", "currency": "East Caribbean Dollar", "code": "XCD", "id": "951" },
    { "country": "SAINT MARTIN (FRENCH PART)", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "SAINT PIERRE AND MIQUELON", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "SAINT VINCENT AND THE GRENADINES", "currency": "East Caribbean Dollar", "code": "XCD", "id": "951" },
    { "country": "SAMOA", "currency": "Tala", "code": "WST", "id": "882" },
    { "country": "SAN MARINO", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "SAO TOME AND PRINCIPE", "currency": "Dobra", "code": "STN", "id": "930" },
    { "country": "SAUDI ARABIA", "currency": "Saudi Riyal", "code": "SAR", "id": "682" },
    { "country": "SENEGAL", "currency": "CFA Franc BCEAO", "code": "XOF", "id": "952" },
    { "country": "SERBIA", "currency": "Serbian Dinar", "code": "RSD", "id": "941" },
    { "country": "SEYCHELLES", "currency": "Seychelles Rupee", "code": "SCR", "id": "690" },
    { "country": "SIERRA LEONE", "currency": "Leone", "code": "SLL", "id": "694" },
    { "country": "SINGAPORE", "currency": "Singapore Dollar", "code": "SGD", "id": "702" },
    { "country": "SINT MAARTEN (DUTCH PART)", "currency": "Netherlands Antillean Guilder", "code": "ANG", "id": "532" },
    { "country": "SLOVAKIA", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "SLOVENIA", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "SOLOMON ISLANDS", "currency": "Solomon Islands Dollar", "code": "SBD", "id": "090" },
    { "country": "SOMALIA", "currency": "Somali Shilling", "code": "SOS", "id": "706" },
    { "country": "SOUTH AFRICA", "currency": "Rand", "code": "ZAR", "id": "710" },
    { "country": "SOUTH SUDAN", "currency": "South Sudanese Pound", "code": "SSP", "id": "728" },
    { "country": "SPAIN", "currency": "Euro", "code": "EUR", "id": "978" },
    { "country": "SRI LANKA", "currency": "Sri Lanka Rupee", "code": "LKR", "id": "144" },
    { "country": "SUDAN (THE)", "currency": "Sudanese Pound", "code": "SDG", "id": "938" },
    { "country": "SURINAME", "currency": "Surinam Dollar", "code": "SRD", "id": "968" },
    { "country": "SVALBARD AND JAN MAYEN", "currency": "Norwegian Krone", "code": "NOK", "id": "578" },
    { "country": "ESWATINI", "currency": "Lilangeni", "code": "SZL", "id": "748" },
    { "country": "SWEDEN", "currency": "Swedish Krona", "code": "SEK", "id": "752" },
    { "country": "SWITZERLAND", "currency": "Swiss Franc", "code": "CHF", "id": "756" },
    { "country": "SWITZERLAND", "currency": "WIR Euro", "code": "CHE", "id": "947" },
    { "country": "SWITZERLAND", "currency": "WIR Franc", "code": "CHW", "id": "948" },
    { "country": "SYRIAN ARAB REPUBLIC", "currency": "Syrian Pound", "code": "SYP", "id": "760" },
    { "country": "TAIWAN (PROVINCE OF CHINA)", "currency": "New Taiwan Dollar", "code": "TWD", "id": "901" },
    { "country": "TAJIKISTAN", "currency": "Somoni", "code": "TJS		972" },
    { "country": "TANZANIA, UNITED REPUBLIC OF", "currency": "Tanzanian Shilling", "code": "TZS", "id": "834" },
    { "country": "THAILAND", "currency": "Baht", "code": "THB", "id": "764" },
    { "country": "TIMOR-LESTE", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "TOGO", "currency": "CFA Franc BCEAO", "code": "XOF", "id": "952" },
    { "country": "TOKELAU", "currency": "New Zealand Dollar", "code": "NZD", "id": "554" },
    { "country": "TONGA", "currency": "Pa’anga", "code": "TOP", "id": "776" },
    { "country": "TRINIDAD AND TOBAGO", "currency": "Trinidad and Tobago Dollar", "code": "TTD", "id": "780" },
    { "country": "TUNISIA", "currency": "Tunisian Dinar", "code": "TND", "id": "788" },
    { "country": "TURKEY", "currency": "Turkish Lira", "code": "TRY", "id": "949" },
    { "country": "TURKMENISTAN", "currency": "Turkmenistan New Manat", "code": "TMT", "id": "934" },
    { "country": "TURKS AND CAICOS ISLANDS (THE)", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "TUVALU", "currency": "Australian Dollar", "code": "AUD", "id": "036" },
    { "country": "UGANDA", "currency": "Uganda Shilling", "code": "UGX", "id": "800" },
    { "country": "UKRAINE", "currency": "Hryvnia", "code": "UAH", "id": "980" },
    { "country": "UNITED ARAB EMIRATES (THE)", "currency": "UAE Dirham", "code": "AED", "id": "784" },
    { "country": "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)", "currency": "Pound Sterling", "code": "GBP", "id": "826" },
    { "country": "UNITED STATES MINOR OUTLYING ISLANDS (THE)", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "UNITED STATES OF AMERICA (THE)", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "UNITED STATES OF AMERICA (THE)", "currency": "US Dollar (Next day)", "code": "USN", "id": "997" },
    { "country": "URUGUAY", "currency": "Peso Uruguayo", "code": "UYU", "id": "858" },
    { "country": "URUGUAY", "currency": "Uruguay Peso en Unidades Indexadas (UI)", "code": "UYI", "id": "940" },
    { "country": "URUGUAY", "currency": "Unidad Previsional", "code": "UYW", "id": "927" },
    { "country": "UZBEKISTAN", "currency": "Uzbekistan Sum", "code": "UZS", "id": "860" },
    { "country": "VANUATU", "currency": "Vatu", "code": "VUV", "id": "548" },
    { "country": "VENEZUELA (BOLIVARIAN REPUBLIC OF)", "currency": "Bolívar Soberano", "code": "VES", "id": "928" },
    { "country": "VIET NAM", "currency": "Dong", "code": "VND", "id": "704" },
    { "country": "VIRGIN ISLANDS (BRITISH)", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "VIRGIN ISLANDS (U.S.)", "currency": "US Dollar", "code": "USD", "id": "840" },
    { "country": "WALLIS AND FUTUNA", "currency": "CFP Franc", "code": "XPF", "id": "953" },
    { "country": "WESTERN SAHARA", "currency": "Moroccan Dirham", "code": "MAD", "id": "504" },
    { "country": "YEMEN", "currency": "Yemeni Rial", "code": "YER", "id": "886" },
    { "country": "ZAMBIA", "currency": "Zambian Kwacha", "code": "ZMW", "id": "967" },
    { "country": "ZIMBABWE", "currency": "Zimbabwe Dollar", "code": "ZWL", "id": "932" },
]

export const DISPOSITIONS = [
    { "name": "PART" },
    { "name": "ALL" },
    { "name": "EXCL" },
    { "name": "EXEMPT" },
    { "name": "XFER" },
    { "name": "UNABLE" },
    { "name": "ABANDON" },
    { "name": "INFORMAL" },
    { "name": "NOREC" },
    { "name": "ORD" },
    { "name": "ORNI" },
    { "name": "ORI" },
    { "name": "CNPR" },
    { "name": "NCND" },
]

export const SOURCE = [
    { "name": "Academia" },
    { "name": "Business" },
    { "name": "Media" },
    { "name": "Organization" },
    { "name": "Public" },
    { "name": "Internal" },
    { "name": "External Govt Dept" },
    { "name": "Govt Other (Province, Municipality, etc)" },
    { "name": "Decline to Identify" },
]

export const EVENTS = [
    {"key": "Assign", "sortKey": "ASSI", "description_en": "Assigned to Officer", "description_fr": "Affecté à l'officier", "reqStatus": "Assign"},
    {"key": "InfoComplete", "sortKey": "COMP", "description_en": "Complete Request Received", "description_fr": "Demande complète reçue", "reqStatus": null},
    {"key": "Consult", "sortKey": "CONS", "description_en": "Consultation ongoing", "description_fr": "Consultation en cours", "reqStatus": "Consult"},
    {"key": "FFF Estimate Send", "sortKey": "Fee", "description_en": "FEE Estimate Sent", "description_fr": "Estimation des frais envoyés", "reqStatus": null},
    {"key": "Release", "sortKey": "FINA", "description_en": "Final Release Documents","description_fr": "Documents de version finale", "reqStatus": "Release"},
    {"key": "Approval", "sortKey": "FORA", "description_en": "For Approval (Review Manager)","description_fr": "Pour approbation (Gestionnaire de révision)", "reqStatus": "Approval"},
    {"key": "ApprovalSnt", "sortKey": "FORA", "description_en": "For Approval (Review Senior ATIP Advisor)", "description_fr": "Pour approbation(Révision du conseiller principal)", "reqStatus": null},
    {"key": "New", "sortKey": "NEW", "description_en": "New", "description_fr": "Nouveau", "reqStatus": "New"},
    {"key": "NotAssigned", "sortKey": "NOTA", "description_en": "Not Assigned to Officer","description_fr": "Non affecté à l'agent", "reqStatus": null},
    {"key": "Hold", "sortKey": "ONHO", "description_en": "On Hold", "description_fr": "En attente", "reqStatus": "Hold"},
    {"key": "Partial", "sortKey": "PART", "description_en": "Partial Release", "description_fr": "Libération partielle", "reqStatus": "Partial"},
    {"key": "PrepApproval", "sortKey": "PREP", "description_en": "Preparing for Approval (Review ATIP)", "description_fr": "Préparation à l'approbation(Révision de l'AIPRP)", "reqStatus": null},
    {"key": "Dispose", "sortKey": "PURG", "description_en": "Purge Request", "description_fr": "Demande de purge", "reqStatus": "Dispose"},
    {"key": "Reopen", "sortKey": "REOP", "description_en": "Reopen Closed Request", "description_fr": "Rouvrir la demande fermée", "reqStatus": "Reopen"},
    {"key": "Cancel", "sortKey": "REQU", "description_en": "Request Abandoned", "description_fr": "Demande abandonnée", "reqStatus": null},
    {"key": "Complete", "sortKey": "REQU", "description_en": "Request Closed", "description_fr": "Demande fermée", "reqStatus": "Complete"},
    {"key": "Retrieval", "sortKey": "RETR", "description_en": "Retrieving Documents", "description_fr": "Récupération de documents", "reqStatus": "Retrieval"},
    {"key": "Review", "sortKey": "REVI", "description_en": "Reviewing Documents", "description_fr": "Examen des documents", "reqStatus": "Review"},
    {"key": "Extension", "sortKey": "EXT", "description_en": "Extension", "description_fr": "Extension", "reqStatus": "Extension" },
]

export const OLDEVENTS = [
    { "value": "9A Extension", "name": "9(1)(a) Extension Search/Interference" },
    { "value": "9B Extension", "name": "9(1)(b)  Extension Consultation" },
    { "value": "9C Extension", "name": "9(1)(c)  Extension subsection 27(1)" },
    { "value": "release-hold", "name": "Release Hold" },
    { "value": "15A1Ext", "name": "15(a)(i) Extension due to interference" },
    { "value": "15A2Ext", "name": "15(a)(ii) Extension for consultations" },
    { "value": "15BExt", "name": "15(b) Extension for translation" },
    { "value": "Cancel", "name": "Request Abandoned" },
    { "value": "AppFee", "name": "Acknowledge receipt and (or) request missing application fee" },
    { "value": "Assist", "name": "Assist applicant" },
    { "value": "ATIP_Advisory", "name": "ATIP Advisory"},
    { "value": "Assign", "name": "Assigned to Officer" },
    { "value": "Cancel", "name": "Cancel Request" },
    { "value": "Complete", "name": "Close Request" },
    { "value": "Complaint", "name": "Complaint" },
    { "value": "ClarPre", "name": "Clarification before complete request" },
    { "value": "Clarification", "name": "Need Clarification from Requester" },
    { "value": "Compl_oic", "name": "Complaint - Correspondence" },
    { "value": "comp-hi", "name": "Complaint - Highlighting documents" },
    { "value": "Complaint_s31", "name": "Complaint - Section 31" },
    { "value": "Complaint_finding", "name": "Complaint - finding" },
    { "value": "Complaint_prep_approve", "name": "Complaint - Prepare for Approval" },
    { "value": "Complaint_Scan", "name": "Complaint - Document Scanning" },
    { "value": "Complaint_Index", "name": "Complaint - Index document" },
    { "value": "Complaint_ATIP_Advisory", "name": "Complaint - ATIP Advisory" },
    { "value": "Complaint_Abandon", "name": "Complaint - Request Abandoned" },
    { "value": "Out on Consultation", "name": "Complaint - Consultation with Other Government Departments - OGDs" },
    { "value": "Out on Consultation", "name": "Complaint - Consultation with third party" },
    { "value": "Out on Consultation", "name": "Complaint - Consultation with other levels of government" },
    { "value": "Out on Consultation", "name": "Complaint - Consultation with PCO Legal" },
    { "value": "Out on Consultation", "name": "Consultation with Legal Services for PCO Cabinet Confidences" },
    { "value": "Out on Consultation", "name": "Consultation with Other Government Departments - OGDs" },
    { "value": "Out on Consultation", "name": "Consultation with third party" },
    { "value": "Out on Consultation", "name": "Consultation with other levels of government" },
    { "value": "Out on Consultation", "name": "Consultation with PCO Legal" },
    { "value": "Complaint_Retrieval", "name": "Complaint - Call Out to Sector - Retrieve Documents" },
    { "value": "Complaint_Review", "name": "Complaint - Review Documents" },
    { "value": "Complaint_Location", "name": "Complaint - Location of file and records" },
    { "value": "Complaint_closed", "name": "Complaint - closed" },
    { "value": "Complaint_rec_oic", "name": "Complaint – records provided to OIC/OPC" },
    { "value": "Complaint_oic_reqrep", "name": "Complaint – OIC/OPC request for representations" },
    { "value": "Complaint_rep_to_oic", "name": "Complaint – representations provided to OIC/OPC" },
    { "value": "Complaint_follow_oic", "name": "Complaint – follow-up from OIC/OPC requesting further representations" },
    { "value": "Complaint_s35_s33", "name": "Complaint – s. 35 notice received from OIC / s. 33 notice received from OPC" },
    { "value": "Complaint_s3533_resp", "name": "Complaint – response to s. 35/s. 33 notice provided" },
    { "value": "Complaint_s37_recvd", "name": "Complaint – s. 37 notice received from OIC / s. 35 notice received from OPC" },
    { "value": "Complaint_resp_s3735", "name": "Complaint – response to s. 37/s. 35 notice provided" },
    { "value": "Complaint_s35", "name": "Complaint - Section 35" },
    { "value": "Complaint_s37", "name": "Complaint - Section 37" },
    { "value": "Correction", "name": "Correction" },
    { "value": "Court", "name": "Court" },
    { "value": "Court_s41", "name": "Court - Section 41" },
    { "value": "Court_s42", "name": "Court - Section 42" },
    { "value": "Court_s44", "name": "Court - Section 44" },
    { "value": "NoteToFile", "name": "Note to File" },
    { "value": "Retrieval", "name": "Call Out to Sector - Retrieve Documents" },
    { "value": "Release", "name": "Final Release of Documents" },
    { "value": "InfoComplete", "name": "Date Complete Request Received" },
    { "value": "Scan", "name": "Document Scanning" },
    { "value": "TotalExempt", "name": "Exempt in its entirety" },
    { "value": "AExtendRationale", "name": "Extension Rationale" },
    { "value": "Highlight", "name": "Highlight Documents (with Sector representations)" },
    { "value": "History", "name": "History" },
    { "value": "Index", "name": "Index document" },
    { "value": "InfoOnly", "name": "Info Only" },
    { "value": "LATE", "name": "Late Request" },
    { "value": "LegalAdvice", "name": "Legal Advice" },
    { "value": "Location", "name": "Location of file and records" },
    { "value": "Missing", "name": "Missing Documents" },
    { "value": "NOCOPY", "name": "NO COPY - contains personal and/or business info" },
    { "value": "Obtain_rec", "name": "Obtain recommendations (from sector(s))" },
    { "value": "PartialRelease", "name": "Partial Release (Interim Response)" },
    { "value": "prep_approve", "name": "Prepare for Approval" },
    { "value": "Reactivate", "name": "Reactivate Request" },
    { "value": "ToBeAssigned", "name": "Ready to be Assigned" },
    { "value": "Revised", "name": "Request Revised" },
    { "value": "Review", "name": "Review Documents" },
    { "value": "Fees_search_prep", "name": "Search-preparation (addt. fees for more than 5 hours) " },
    { "value": "ConsultInt", "name": "Sector Review" },
    { "value": "SectorFees", "name": "Sector Fees" },
    { "value": "Correspond", "name": "Send Out Correspondence" },
    { "value": "Approval", "name": "Sent for Approval" },
    { "value": "Sent", "name": "Sent to Requester" },
    { "value": "SuppRelease", "name": "Supplemental Release" },
    { "value": "Transfer", "name": "Transfer Request" },
    { "value": "triage", "name": "Triage documents" },
    { "value": "Validate", "name": "Validate Request" },
    { "value": "Workplan", "name": "Work Plan" },
] 

export const CLAUSES = [
    {'value': 'Duplicate - Copie', 'text': 'Duplicate'},
    {'value': 'Not rel - Non pert','text': 'Not Relevent'},
    {'value': 'Excluded - Exclu', 'text': 'Excluded'},
    {'value': 'P 12.(1)', 'text': 'Right of access'},
    {'value': 'P 13 (1)', 'text': 'Subject to subsection'},
    {'value': 'P 13 (1)(a)', 'text': 'The government of a foreign state or an institution thereof;'},
    {'value': 'P 13 (1)(b)', 'text': 'an international organization of states or an institution thereof'},
    {'value': 'P 13 (1)(c)', 'text': 'the government of a province or an institution thereof'},
    {'value': 'P 13 (1)(d)', 'text': 'a municipal or regional government established by or pursuant to an Act of the legislature of a province or an institution of such a government'},
    {'value': 'P 13 (1)(e)', 'text': 'an aboriginal government'},
    {'value': 'P 13 (2)(a)', 'text': 'consents to the disclosure'},
    {'value': 'P 13 (2)(b)', 'text': 'makes the information public'},
    {'value': 'P 13 (2)(a)', 'text': 'Nisga’a Government, as defined in the Nisga’a Final Agreement given effect by the Nisga’a Final Agreement Act'},
    {'value': 'P 13 (2)(b)', 'text': 'the council, as defined in the Westbank First Nation Self-Government Agreement given effect by the Westbank First Nation Self-Government Act'},
    {'value': 'P 13 (2)(c)', 'text': 'the Tlicho Government, as defined in section 2 of the Tlicho Land Claims and Self-Government Act'},
    {'value': 'P 13 (2)(d)', 'text': ' the Nunatsiavut Government, as defined in section 2 of the Labrador Inuit Land Claims Agreement Act'},
    {'value': 'P 13 (2)(e)', 'text': ' the council of a participating First Nation as defined in subsection 2(1) of the First Nations Jurisdiction over Education in British Columbia Act'},
    {'value': 'P 13 (2)(f)', 'text': 'the Tsawwassen Government, as defined in subsection 2(2) of the Tsawwassen First Nation Final Agreement Act'},
    {'value': 'P 13 (2)(f1)', 'text': 'the Cree Nation Government, as defined in subsection 2(1) of the Cree Nation of Eeyou Istchee Governance Agreement Act or a Cree First Nation, as defined in subsection 2(2) of that Act'},
    {'value': 'P 13 (2)(g)', 'text': 'a Maanulth Government, within the meaning of subsection 2(2) of the Maanulth First Nations Final Agreement Act'},
    {'value': 'P 13 (2)(h)', 'text': 'Sioux Valley Dakota Oyate Government, within the meaning of subsection 2(2) of the Sioux Valley Dakota Nation Governance Act'},
    {'value': 'P 13 (2)(i)', 'text': 'the council of a participating First Nation, as defined in section 2 of the Anishinabek Nation Education Agreement Act'},
    {'value': 'P 14 (a)', 'text': 'on federal-provincial consultations or deliberations'},
    {'value': 'P 14 (b)', 'text': 'on strategy or tactics adopted or to be adopted by the Government of Canada relating to the conduct of federal-provincial affairs'},
    {'value': 'P 15 (1)', 'text': 'The head of a government institution may refuse to disclose any record requested under this Part that contains information the disclosure of which could reasonably be expected to be injurious to the conduct of international affairs, the defence of Canada or any state allied or associated with Canada or the detection, prevention or suppression of subversive or hostile activities, including, without restricting the generality of the foregoing, any such information'},
    {'value': 'P 15 (1)(a)', 'text': 'relating to military tactics or strategy, or relating to military exercises or operations undertaken in preparation for hostilities or in connection with the detection, prevention or suppression of subversive or hostile activities'},
    {'value': 'P 15 (1)(b)', 'text': 'relating to the quantity, characteristics, capabilities or deployment of weapons or other defence equipment or of anything being designed, developed, produced or considered for use as weapons or other defence equipment'},
    {'value': 'P 15 (1)(c)', 'text': 'relating to the characteristics, capabilities, performance, potential, deployment, functions or role of any defence establishment, of any military force, unit or personnel or of any organization or person responsible for the detection, prevention or suppression of subversive or hostile activities'},
    {'value': 'P 15 (1)(d)', 'text': 'obtained or prepared for the purpose of intelligence relating to'},
    {'value': 'P 15 (1)(d)(i)', 'text': 'the defence of Canada or any state allied or associated with Canada'},
    {'value': 'P 15 (1)(d)(ii)', 'text': 'the detection, prevention or suppression of subversive or hostile activities'},
    {'value': 'P 15 (1)(e)', 'text': 'obtained or prepared for the purpose of intelligence respecting foreign states, international organizations of states or citizens of foreign states used by the Government of Canada in the process of deliberation and consultation or in the conduct of international affairs'},
    {'value': 'P 15 (1)(f)', 'text': 'on methods of, and scientific or technical equipment for, collecting, assessing or handling information referred to in paragraph (d) or (e) or on sources of such information'},
    {'value': 'P 15 (1)(g)', 'text': 'on the positions adopted or to be adopted by the Government of Canada, governments of foreign states or international organizations of states for the purpose of present or future international negotiations'},
    {'value': 'P 15 (1)(h)', 'text': 'that constitutes diplomatic correspondence exchanged with foreign states or international organizations of states or official correspondence exchanged with Canadian diplomatic missions or consular posts abroad'},
    {'value': 'P 15 (1)(h)(i)', 'text': 'relating to the communications or cryptographic systems of Canada or foreign states used'},
    {'value': 'P 15 (1)(h)(i)', 'text': 'for the conduct of international affairs,'},
    {'value': 'P 15 (1)(h)(ii)', 'text': 'for the defence of Canada or any state allied or associated with Canada, or'},
    {'value': 'P 15 (1)(h)(iii)', 'text': 'in relation to the detection, prevention or suppression of subversive or hostile activities.'},
    {'value': 'P 15 (2)', 'text': 'defence of Canada or any state allied or associated with Canada includes the efforts of Canada and of foreign states toward the detection, prevention or suppression of activities of any foreign state directed toward actual or potential attack or other acts of aggression against Canada or any state allied or associated with Canada'},
    {'value': 'P 15 (2)(a)','text': 'espionage against Canada or any state allied or associated with Canada'},
    {'value': 'P 15 (2)(b)','text': 'sabotage'},
    {'value': 'P 15 (2)(c)','text': 'activities directed toward the commission of terrorist acts, including hijacking, in or against Canada or foreign states'},
    {'value': 'P 15 (2)(d)','text': 'activities directed toward accomplishing government change within Canada or foreign states by the use of or the encouragement of the use of force, violence or any criminal means'},
    {'value': 'P 15 (2)(e)','text': 'activities directed toward gathering information used for intelligence purposes that relates to Canada or any state allied or associated with Canada, an'},
    {'value': 'P 15 (2)(f)','text': 'activities directed toward threatening the safety of Canadians, employees of the Government of Canada or property of the Government of Canada outside Canada'},
    {'value': 'P 16 (1)','text': 'The head of a government institution may refuse to disclose any record requested under this Part that contains'},
    {'value': 'P 16 (1)(a)','text': 'information obtained or prepared by any government institution, or part of any government institution, that is an investigative body specified in the regulations in the course of lawful investigations pertaining to'},
    {'value': 'P 16 (1)(a)(i)','text': 'the detection, prevention or suppression of crime,'},
    {'value': 'P 16 (1)(a)(ii)','text': 'the enforcement of any law of Canada or a province, or'},
    {'value': 'P 16 (1)(a)(iii)','text': 'activities suspected of constituting threats to the security of Canada within the meaning of the Canadian Security Intelligence Service Act'},
    {'value': 'P 16 (1)(b)','text': 'information relating to investigative techniques or plans for specific lawful investigations'},
    {'value': 'P 16 (1)(c)','text': 'information the disclosure of which could reasonably be expected to be injurious to the enforcement of any law of Canada or a province or the conduct of lawful investigations, including, without restricting the generality of the foregoing, any such information'},
    {'value': 'P 16 (1)(c)(i)', 'text': 'relating to the existence or nature of a particular investigation,'},
    {'value': 'P 16 (1)(c)(ii)', 'text': 'that would reveal the identity of a confidential source of information, or'},
    {'value': 'P 16 (1)(c)(iii)', 'text': 'that was obtained or prepared in the course of an investigation; or'},
    {'value': 'P 16 (1)(d)', 'text': 'information the disclosure of which could reasonably be expected to be injurious to the security of penal institutions'},
    {'value': 'P 16 (2)','text': 'The head of a government institution may refuse to disclose any record requested under this Part that contains information that could reasonably be expected to facilitate the commission of an offence, including, without restricting the generality of the foregoing, any such information'},
    {'value': 'P 16 (2)(a)','text': 'on criminal methods or techniques'},
    {'value': 'P 16 (2)(b)','text': 'that is technical information relating to weapons or potential weapons'},
    {'value': 'P 16 (2)(c)','text': 'on the vulnerability of particular buildings or other structures or systems, including computer or communication systems, or methods employed to protect such buildings or other structures or systems'},
    {'value': 'P 16 (3)','text': 'The head of a government institution shall refuse to disclose any record requested under this Part that contains information that was obtained or prepared by the Royal Canadian Mounted Police while performing policing services for a province or municipality pursuant to an arrangement made under section 20 of the Royal Canadian Mounted Police Act, where the Government of Canada has, on the request of the province or municipality agreed not to disclose such information.'},
    {'value': 'P 16 (4)','text': 'For the purposes of paragraphs (1)(b) and (c), investigation means an investigation'},
    {'value': 'P 16 (4)(a)','text': 'pertains to the administration or enforcement of an Act of Parliament'},
    {'value': 'P 16 (4)(b)','text': 'is authorized by or pursuant to an Act of Parliament'},
    {'value': 'P 16 (4)(c)','text': 'is within a class of investigations specified in the regulations'},
    {'value': 'P 16.1 (1)','text': 'The following heads of government institutions shall refuse to disclose any record requested under this Part that contains information that was obtained or created by them or on their behalf in the course of an investigation, examination or audit conducted by them or under their authority'},
    {'value': 'P 16.1 (1)(a)','text': 'the Auditor General of Canada'},
    {'value': 'P 16.1 (1)(b)','text': 'the Commissioner of Official Languages for Canada'},
    {'value': 'P 16.1 (1)(c)','text': 'the Information Commissioner'},    {'value': 'P 19.(1)', 'text': 'Personal information obtained in confidence'},
    {'value': 'P 16.1 (1)(d)','text': 'the Privacy Commissioner'},
    {'value': 'P 16.1 (2)','text': 'However, the head of a government institution referred to in paragraph (1)(c) or (d) shall not refuse under subsection (1) to disclose any record that contains information that was created by or on behalf of the head of the government institution in the course of an investigation or audit conducted by or under the authority of the head of the government institution once the investigation or audit and all related proceedings, if any, are finally concluded.'},
    {'value': 'P 16.2 (1)','text': ' The Commissioner of Lobbying shall refuse to disclose any record requested under this Part that contains information that was obtained or created by the Commissioner or on the Commissioner’s behalf in the course of an investigation conducted by or under the authority of the Commissioner.'},
    {'value': 'P 16.2 (2)','text': 'However, the Commissioner shall not refuse under subsection (1) to disclose any record that contains information that was created by the Commissioner or on the Commissioner’s behalf in the course of an investigation conducted by, or under the authority of, the Commissioner once the investigation and all related proceedings, if any, are finally concluded'},
    {'value': 'P 16.3','text': 'Subject to section 541 of the Canada Elections Act, the Chief Electoral Officer may refuse to disclose any record requested under this Part that contains information that was obtained or created by or on behalf of a person who conducts an investigation, examination or review in the performance of their functions under the Canada Elections Act.'},
    {'value': 'P 16.31','text': '[Repealed, 2018, c. 31, s. 391]'},
    {'value': 'P 16.4 (1)','text': 'The Public Sector Integrity Commissioner shall refuse to disclose any record requested under this Part that contains information'},
    {'value': 'P 16.4 (1)(a)','text': ' obtained or created by him or her or on his or her behalf in the course of an investigation into a disclosure made under the Public Servants Disclosure Protection Act or an investigation commenced under section 33 of that Act'},
    {'value': 'P 16.4 (1)(b)','text': 'received by a conciliator in the course of attempting to reach a settlement of a complaint filed under subsection 19.1(1) of that Act'},
    {'value': 'P 16.4 (2)','text': 'Subsection (1) does not apply in respect of a record that contains information referred to in paragraph (1)(b) if the person who gave the information to the conciliator consents to the record being disclosed'},
    {'value': 'P 16.5','text': 'The head of a government institution shall refuse to disclose any record requested under this Part that contains information created for the purpose of making a disclosure under the Public Servants Disclosure Protection Act or in the course of an investigation into a disclosure under that Act.'},
    {'value': 'P 16.6','text': 'The Secretariat of the National Security and Intelligence Committee of Parliamentarians shall refuse to disclose any record requested under this Part that contains information obtained or created by it or on its behalf in the course of assisting the National Security and Intelligence Committee of Parliamentarians in fulfilling its mandate'},
    {'value': 'P 17','text': 'The head of a government institution may refuse to disclose any record requested under this Part that contains information the disclosure of which could reasonably be expected to threaten the safety of individuals'},
    {'value': 'P 18','text': 'The head of a government institution may refuse to disclose any record requested under this Part that contains'},
    {'value': 'P 18 (a)','text': 'trade secrets or financial, commercial, scientific or technical information that belongs to the Government of Canada or a government institution and has substantial value or is reasonably likely to have substantial value'},
    {'value': 'P 18 (b)','text': 'information the disclosure of which could reasonably be expected to prejudice the competitive position of a government institution or to interfere with contractual or other negotiations of a government institution'},
    {'value': 'P 18 (c)','text': 'scientific or technical information obtained through research by an officer or employee of a government institution, the disclosure of which could reasonably be expected to deprive the officer or employee of priority of publication'},
    {'value': 'P 18 (d)','text': 'information the disclosure of which could reasonably be expected to be materially injurious to the financial interests of a government institution or to the ability of the Government of Canada to manage the economy of Canada or could reasonably be expected to result in an undue benefit to any person, including such information that relates to'},
    {'value': 'P 18 (d)(i)','text': 'the currency, coinage or legal tender of Canada'},
    {'value': 'P 18 (d)(ii)','text': 'a contemplated change in the rate of bank interest or in government borrowing'},
    {'value': 'P 18 (d)(iii)','text': 'a contemplated change in tariff rates, taxes, duties or any other revenue source'},
    {'value': 'P 18 (d)(iv)','text': 'a contemplated change in the conditions of operation of financial institutions'},
    {'value': 'P 18 (d)(v)','text': 'a contemplated sale or purchase of securities or of foreign or Canadian currency'},
    {'value': 'P 18 (d)(vi)','text': 'a contemplated sale or acquisition of land or property'},
    {'value': 'P 18.1 (1)','text': 'The head of a government institution may refuse to disclose a record requested under this Part that contains trade secrets or financial, commercial, scientific or technical information that belongs to, and has consistently been treated as confidential by'},
    {'value': 'P 18.1 (1)(a)','text': 'the Canada Post Corporation'},
    {'value': 'P 18.1 (1)(b)','text': 'Export Development Canada'},
    {'value': 'P 18.1 (1)(c)','text': 'the Public Sector Pension Investment Board'},
    {'value': 'P 18.1 (1)(d)','text': 'VIA Rail Canada Inc.'},
    {'value': 'P 18.1 (2)','text': 'However, the head of a government institution shall not refuse under subsection (1) to disclose a part of a record that contains information that relates to'},
    {'value': 'P 18.1 (2)(a)','text': 'the general administration of an institution referred to in any of paragraphs (1)(a) to (d)'},
    {'value': 'P 18.1 (2)(b)','text': 'the Privacy Commissioner'},
    {'value': 'P 19.(1)(a)', 'text': 'Personal information obtained in confidence (from the government of a foreign state)'},
    {'value': 'P 19.(1)(b)', 'text': 'Personal information obtained in confidence (from an international organization of states)'},
    {'value': 'P 19.(1)(c)', 'text': 'Personal information obtained in confidence (from the governement of a province)'},
    {'value': 'P 19.(1)(d)', 'text': 'Personal information obtained in confidence (from a municipal or regional government)'},
    {'value': 'P 19.(1)(e)', 'text': 'Personal information obtained in confidence (from the council'    },
    {'value': 'P 19.(1)(f)', 'text': 'Personal information obtained in confidence (from the council of a participating First Nation)'},
    {'value': 'P 20.(1)', 'text': 'Subject to this section, the head of a government institution shall refuse to disclose any record requested under this Part that contains'},
    {'value': 'P 20.(1)(a)', 'text': 'trade secrets of a third party'},
    {'value': 'P 20.(1)(b)', 'text': 'financial, commercial, scientific or technical information that is confidential information supplied to a government institution by a third party and is treated consistently in a confidential manner by the third party'},
    {'value': 'P 20.(1)(b.1)', 'text': 'information that is supplied in confidence to a government institution by a third party for the preparation, maintenance, testing or implementation by the government institution of emergency management plans within the meaning of section 2 of the Emergency Management Act and that concerns the vulnerability of the third party’s buildings or other structures, its networks or systems, including its computer or communications networks or systems, or the methods used to protect any of those buildings, structures, networks or systems'},
    {'value': 'P 20.(1)(c)', 'text': 'information the disclosure of which could reasonably be expected to result in material financial loss or gain to, or could reasonably be expected to prejudice the competitive position of, a third party'},
    {'value': 'P 20.(1)(d)', 'text': 'information the disclosure of which could reasonably be expected to interfere with contractual or other negotiations of a third party'},
    {'value': 'P 20.(2)', 'text': 'The head of a government institution shall not, pursuant to subsection (1), refuse to disclose a part of a record if that part contains the results of product or environmental testing carried out by or on behalf of a government institution unless the testing was done as a service to a person, a group of persons or an organization other than a government institution and for a fee'},
    {'value': 'P 20.(3)', 'text': 'Where the head of a government institution discloses a record requested under this Part, or a part thereof, that contains the results of product or environmental testing, the head of the institution shall at the same time as the record or part thereof is disclosed provide the person who requested the record with a written explanation of the methods used in conducting the test'},
    {'value': 'P 20.(4)', 'text': 'For the purposes of this section, the results of product or environmental testing do not include the results of preliminary testing conducted for the purpose of developing methods of testing'},
    {'value': 'P 20.(5)', 'text': 'The head of a government institution may disclose any record that contains information described in subsection (1) with the consent of the third party to whom the information relates'},
    {'value': 'P 20.(6)', 'text': 'The head of a government institution may disclose all or part of a record requested under this Part that contains information described in any of paragraphs (1)(b) to (d)'},
    {'value': 'P 20.(6)(a)', 'text': 'the disclosure would be in the public interest as it relates to public health, public safety or protection of the environment'},
    {'value': 'P 20.(6)(b)', 'text': 'the public interest in disclosure clearly outweighs in importance any financial loss or gain to a third party, any prejudice to the security of its structures, networks or systems, any prejudice to its competitive position or any interference with its contractual or other negotiations'},
    {'value': 'P 20.1', 'text': 'The head of the Public Sector Pension Investment Board shall refuse to disclose a record requested under this Part that contains advice or information relating to investment that the Board has obtained in confidence from a third party if the Board has consistently treated the advice or information as confidential'},
    {'value': 'P 20.2', 'text': 'The head of the Canada Pension Plan Investment Board shall refuse to disclose a record requested under this Part that contains advice or information relating to investment that the Board has obtained in confidence from a third party if the Board has consistently treated the advice or information as confidential'},
    {'value': 'P 20.4', 'text': 'The head of the National Arts Centre Corporation shall refuse to disclose a record requested under this Part if the disclosure would reveal the terms of a contract for the services of a performing artist or the identity of a donor who has made a donation in confidence and if the Corporation has consistently treated the information as confidential'},
    {'value': 'P 21.', 'text': 'International affairs and defence'},
    {'value': 'P 21.(1)', 'text': 'The head of a government institution may refuse to disclose any record requested under this Part that contains'},
    {'value': 'P 21.(1)(a)', 'text': 'advice or recommendations developed by or for a government institution or a minister of the Crown'},
    {'value': 'P 21.(1)(b)', 'text': 'an account of consultations or deliberations in which directors, officers or employees of a government institution, a minister of the Crown or the staff of a minister participate'},
    {'value': 'P 21.(1)(c)', 'text': 'positions or plans developed for the purpose of negotiations carried on or to be carried on by or on behalf of the Government of Canada and considerations relating thereto'},
    {'value': 'P 21.(1)(d)', 'text': 'plans relating to the management of personnel or the administration of a government institution that have not yet been put into operation'},
    {'value': 'P 21.(2)', 'text': 'Subsection (1) does not apply in respect of a record that contains'},
    {'value': 'P 21.(2)(a)', 'text': 'an account of, or a statement of reasons for, a decision that is made in the exercise of a discretionary power or an adjudicative function and that affects the rights of a person'},
    {'value': 'P 21.(2)(b)', 'text': 'a report prepared by a consultant or an adviser who was not a director, an officer or an employee of a government institution or a member of the staff of a minister of the Crown at the time the report was prepared'},
    {'value': 'P 22.(1)(a)(i)', 'text':  'Law enforcement and investigation (pertaining to the detection'},
    {'value': 'P 22.(1)(a)', 'text':     'Law enforcement and investigation that was obtained or prepared by any government institution'},
    {'value': 'P 22.(1)', 'text':    'Law enforcement and investigation '},
    {'value': 'P 22.(1)(a)(ii)', 'text':     'Law enforcement and investigation (the enforcement of any law)'},
    {'value': 'P 22.(1)(a)(iii)', 'text':    'Law enforcement and investigation (constituting threats to the security of Canada)'},
    {'value': 'P 22.(1)(b)(i)', 'text':  'Law enforcement and investigation (disclosure of information could be injurious relating to the existance or nature of a particular investigation)'},
    {'value': 'P 22.(1)(b)', 'text':     'Law enforcement and investigations (injurious to the enforcement of any law of Canada)'},
    {'value': 'P 22.(1)(b)(ii)', 'text':     'Law enforcement and investigation (disclosure of information could be injurious that would reveal the identity of a confidential source)'},
    {'value': 'P 22.(1)(b)(iii)', 'text':    'Law enforcement and investigation (disclosure of information could be injurious that was obtained or prepared in the course of an investigation)'},
    {'value': 'P 22.(1)(c)', 'text':     'Law enforcement and investigation (injurious to the security of penal institutions)'},
    {'value': 'P 22.(2)', 'text':    'Policing services for provinces or municipalities'},
    {'value': 'P 22.1(1)', 'text':   'Information obtained by Privacy Commissioner'},
    {'value': 'P 22.2', 'text':  'Public Sector Integrity Commissioner'},
    {'value': 'P 22.3', 'text':  'Public Servants Disclosure Protection Act'},
    {'value': 'P 23.(a)', 'text':    'Security clearances (required by the Government of Canada)'},
    {'value': 'P 23.', 'text':   'Security clearances'},
    {'value': 'P 23.(b)', 'text':    'Security clearances (reuired by the government of a province)'},
    {'value': 'P 24.(a)', 'text':    'Individuals sentenced for an offence (disclosure could lead to a serious disruption of the individuals institutional'},
    {'value': 'P 24.', 'text':   'Individuals sentenced for an offence'},
    {'value': 'P 24.(b)', 'text':    'Individuals sentenced for an offence (disclosure could reveal information about the individual originally obtained in confidence)'},
    {'value': 'P 25.', 'text':   'Safety of individuals'},
    {'value': 'P 26.', 'text':   'Information about another individual'},
    {'value': 'P 27.', 'text':   'Solicitor-client privilege'},
    {'value': 'P 28.', 'text':   'Medical record'},
    {'value': 'P 70.1(1)', 'text':   'Certificate under the Canada Evidence Act'}
    
]
export const MODULES = [
    {"value": "R", "text": "request"},
    {"value": "D", "text": "document"},
    {"value": "E", "text": "email"}
]