<template>
    <address-table :addressesFiltered="addressesFiltered"></address-table>
</template>

<script>
import AddressTable from "../components/request/AddressTable";
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    components: {
        AddressTable
    },
    data() {
        return {
        addressFilteres:{},
        };
    },
    async created() {
        Object.assign(this.addressFilteres, this.storeAddressFilters);
        await this.filterAddresses(this.addressFilteres);
        await this.clearAddressesFilter();
        await this.retrieveAddresses();
    },

  computed: {
    ...mapGetters('address', {
      'addressesFiltered':'addressesFiltered'
    }),
     ...mapState('address', {
      'storeAddressFilters':'filter'
    }),
  },
  watch: {
    addressesFiltered: {
      handler(){
        Object.assign(this.addressFilteres, this.storeAddressFilters);
      }, deep: true 
    }
  },
  methods: {
    ...mapActions('address',['retrieveAddresses','filterAddresses','clearAddressesFilter']),
  }

}

</script>