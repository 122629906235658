<template>
  <v-card class="ma-0 pa-0 mb-3 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
    <v-card-title class="py-0 my-0 customlightgray">
          <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{tableTitle}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{addressesFiltered?addressesFiltered.length:'0'}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                        </template>
                        <span>{{$t("advanced")}}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="dialog=true" v-if="$checkPermission('Contacts.Create')">{{$t("newaddress")}}</v-btn>
            </v-col>
            
        </v-row>
        
    </v-card-title>
    <!-- add address dialog -->
      <v-dialog v-model="dialog" max-width="800">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{
              formTitle
            }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    v-model="activeAddress.AddressType"
                    :items="getLookup('AddType')"
                    item-text="text"
                    item-value="text"
                    outlined
                    dense
                    :label="$t('addresstypes')"
                    :placeholder="$t('addresstypes')"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    v-model="activeAddress.Source"
                    :items="getLookup('Source')"
                    item-text="text"
                    item-value="text"
                    outlined
                    dense
                    :label="$t('source')"
                    :placeholder="$t('source')"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    v-model="activeAddress.Title"
                    :items="['Master', 'Mr.', 'Monsieur', 'Mrs.', 'Madame', 'Miss', 'Ms.', 'Dr.', 'Superintendent', 'Chief', 'The Honourable', 'Colonel', 'Inspector']"
                    outlined
                    dense
                    :label="$t('title')"
                    :placeholder="$t('title')"
                    :rules="[rules.required, rules.minU]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :label="$t('firstname')"
                    :placeholder="$t('firstname')"
                    v-model="activeAddress.FirstName"
                    outlined
                    dense
                    :rules="[rules.required, rules.minU]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :label="$t('lastname')"
                    :placeholder="$t('lastname')"
                    v-model="activeAddress.LastName"
                    outlined
                    dense
                    :rules="[rules.required, rules.minU]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="$t('Acronym')"
                    :placeholder="$t('Acronym')"
                    v-model="activeAddress.Acronym"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="$t('company')"
                    :placeholder="$t('company')"
                    v-model="activeAddress.CompanyAgency"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field
                    :label="$t('title')"
                    :placeholder="$t('title')"
                    v-model="activeAddress.Title2"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                    <v-text-field
                    :label="$t('streetnumber')"
                    :placeholder="$t('streetnumber')"
                    v-model="activeAddress.AddrNum"
                    outlined
                    dense
                    :rules="[rules.required, rules.sNumber]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="9">
                    <v-text-field
                    :label="$t('street')"
                    :placeholder="$t('street')"
                    v-model="activeAddress.Address"
                    outlined
                    dense
                    :rules="[rules.required, rules.minU]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="$t('city')"
                    :placeholder="$t('city')"
                    v-model="activeAddress.City"
                    outlined
                    dense
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                      v-model="activeAddress.Province"
                    :items="provinces"
                    item-value="name"
                    outlined
                    dense
                    :label="$t('province')"
                    :placeholder="$t('province')"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="$t('postalcode')"
                    :placeholder="$t('postalcode')"
                    v-model="activeAddress.PostalCode"
                    outlined
                    dense
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="activeAddress.Country"
                    :items="countries"
                    item-value="name"
                    outlined
                    hide-details
                    dense
                    :rules="[rules.required]"
                    :label="$t('country')"
                    :placeholder="$t('country')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                    <v-text-field
                    :label="$t('Institution Code')"
                    :placeholder="$t('Institution Code')"
                    v-model="activeAddress.InstitutionCode"
                    outlined 
                    hide-details
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="text-center text-sm-center">
                  <span>{{$t('preferredlanguage')}}*</span>
                  <v-radio-group v-model="activeAddress.LangPref" hide-details :rules="[rules.required]" class="text-center justify-center justify-sm-center" required>
                    <v-radio :label="$t('english')" value="en"></v-radio>
                    <v-radio :label="$t('french')" value="fr"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="3" md="3" class="text-center text-sm-center">
                  <span>{{$t("Mail to Same")}}</span>
                  <v-radio-group v-model="activeAddress.MailtoSame" hide-details class="text-center justify-center justify-sm-center">
                    <v-radio :label="$t('yes')" :value="1"></v-radio>
                    <v-radio :label="$t('no')" :value="0"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="3" md="3" class="text-center text-sm-center">
                  <span>{{$t("Address Status")}}</span>
                   <v-radio-group v-model="activeAddress.ActiveFlag" hide-details class="text-center justify-center justify-sm-center">
                    <v-radio :label="$t('active')" :value="1"></v-radio>
                    <v-radio :label="$t('disabled')" :value="0"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    :label="$t('telephoneextension')"
                    :placeholder="$t('telephoneextension')"
                    v-model="activeAddress.PhoneExt1"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    :label="$t('telephone')"
                    :placeholder="$t('telephone')"
                    v-model="activeAddress.Phone1"
                    :rules="[rules.required]"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                  :label="$t('cellphoneextension')"
                  :placeholder="$t('cellphoneextension')"
                  v-model="activeAddress.PhoneExt2"
                  outlined
                  dense
                  required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    :label="$t('cellphone')"
                    :placeholder="$t('cellphone')"
                    v-model="activeAddress.Phone2"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :label="$t('facsimile')"
                    :placeholder="$t('facsimile')"
                    v-model="activeAddress.Facsimile"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :label="$t('email')"
                    :placeholder="$t('email')"
                    v-model="activeAddress.Email"
                    type="email"
                    outlined
                    dense
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    grow
                    outlined
                    hide-details
                    dense
                    v-model="activeAddress.Comments"
                    :label="$t('comment')"
                    :placeholder="$t('comment')"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="saveAddress">{{$t('save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- //add address dialog -->

    <!-- advanced search dialog -->
      <v-dialog v-model="dialog_adv" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t("advanced")}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-row class="ma-0">
               <v-col cols="12" class="ma-0 py-0">
                    <v-autocomplete
                    v-model="filters.AddressType"
                    :items="getLookup('AddType')"
                    dense
                    small-chips
                    multiple
                    outlined
                    :label="$t('addresstypes')"
                    :placeholder="$t('addresstypes')"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                    <v-autocomplete
                    v-model="filters.Source"
                    :items="getLookup('Source')"
                    dense
                    small-chips
                    multiple
                    outlined
                    :label="$t('addresstypes')"
                    :placeholder="$t('addresstypes')"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                    <v-autocomplete
                    v-model="filters.Country"
                    :items="countries"
                    dense
                    small-chips
                    multiple
                    outlined
                    :label="$t('country')"
                    :placeholder="$t('country')"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                    <v-autocomplete
                    v-model="filters.Province"
                    :items="provinces"
                    dense
                    small-chips
                    multiple
                    outlined
                    :label="$t('province')"
                    :placeholder="$t('province')"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                   <v-text-field class="ma-0" v-model="filters.PostalCode" outlined dense :label="$t('postalcode')" :placeholder="$t('postalcode')"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 py-0">
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialog_adv = false">{{$t("close")}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="findAddresses()">{{$t("search")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- //advanced search dialog -->

    <!-- filters section -->
     <v-divider  v-show="filterList.length>0"></v-divider>       
    <v-row class="pa-0 ma-0 white" v-show="filterList.length>0">
            <v-col class="pa-0 ma-0" cols="12">
              <v-scroll-x-transition group hide-on-leave>
              <v-tooltip v-for="(f, i) in filterList" :key="i" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip  v-on='on' v-bind="attrs"
                    class="ma-1"
                    color="custom-color-accent"
                    close
                    small
                    close-icon="mdi-delete"
                    @click:close="removeFilter(f)" >
                      {{f}}
                    </v-chip>
                  </template>
                  <span>{{$t("removefilter")}}</span>
                </v-tooltip>
              </v-scroll-x-transition>
            </v-col>
    </v-row> 
    <v-divider></v-divider>
    <!-- //filters section -->


    <v-data-table :mobile-breakpoint="1081" 
        :headers="headers"
        :items="addressesFiltered"
        class="elevation-0 customoffwhite text--secondary pa-0 ma-0 clickable"
        :search="search"
        :loading="loading"
        expanded.sync
        show-expand
        sort-by="id"
        :loading-text="$t('loading')"
        :footer-props="{
          'items-per-page-text': $t('rowsperpage'),
        }" 
        @click:row="selectAddress"
    >
      <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
       <template v-slot:expanded-item="{ headers, item }" elevation="0">
        <td class=" my-side-border" :colspan="headers.length" elevation="0">
          <span class="font-weight-bold text--secondary"> {{$t('company')}}: </span><span class="text--secondary">{{ item.CompanyAgency }}</span>
        </td>
      </template>
      <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
         <v-row class="ma-0 pa-0 text-right">
          <v-col cols="12" class="ma-0 pa-0">
             <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Contacts.Update')">
                    <v-icon
                      @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    </div>
  </v-card>
</template>
<script>
import { COUNTRIES } from "@/helpers/exports";
import { PROVINCES } from "@/helpers/exports";
import {mapActions, mapState} from "vuex";
import axcessApi from "@/plugins/axcessApi";
export default {
  props: ["addressesFiltered"],
  data () {
    return {
      dialog: false,
      dialog_adv: false,
      filterList:[],
      activeAddress: {
        LanguagePref: "en",
        Country: "Canada",
        ActiveFlag: 1
      },
      search: "",
      canDelete: false,
      countries: COUNTRIES.map(c => c.name),
      provinces: PROVINCES.map(c => c.name),
      valid:true,
      rules: {
          required: value => !!value || this.$t('required'),
          minU: v => (!v || v.length > 2) || this.$t('mincharacters3'), 
          sNumber: v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) || this.$t('Number has to be between 0 and 99999'),
          email: v => (!v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || this.$t('mailmustbevalid')
      },
      selected: [],
      headers: [
        { text: '', value: 'data-table-expand' },
        {
          text: "Address Id",
          align: "left",
          sortable: false,
          value: "id"
        },
        { text: this.$t('firstname'), value: "FirstName" },
        { text: this.$t('lastname'), value: "LastName" },
        { text: this.$t('street'), value: "Address" },
        { text: this.$t('city'), value: "City" },
        { text: this.$t('telephone'), value: "Phone1" },
        { text: this.$t('type'), value: "AddressType", filter: this.addressTypeFilter },
        { text: "", value: "action", sortable: false }
      ],
      editedIndex: -1,
      AddressType:"",
      AddressTypes: [],
      filters: {
        AddressType: [],
        Source: [],
        Country: [],
        Province: [],
        PostalCode: "",
      },
    };
  },
  async created(){
    Object.assign(this.filters, this.storeFilters);
    this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
    this.filterList = this.filterList.filter(el=> el != "");
  },
  watch:{
    storeFilters: {
      handler(){
          Object.assign(this.filters, this.storeFilters);  
          this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
                this.filterList = this.filterList.filter(el=> el != "");
      }, deep: true 
    },
  },
  computed: {
    ...mapState("configuration", {
      configurations: "configurations",
      loading: "loading",
      requestConfiguration: "requestConfiguration",
    }),
    ...mapState("address", {
      loading: "loading",storeFilters:"filter"
    }),
    formTitle() {
      return this.editedIndex === -1 ? this.$t('newaddress') : this.$t('editaddress');
    },

    tableTitle() {
      return this.$t("Addresses");
    },
  },
  methods: {
    findAddresses(){
      this.close();
      this.filterAddresses(this.filters);
    },
    removeFilter(item){
      Object.keys(this.filters).forEach((key)=>{
      if( !Array.isArray(this.filters[key]) && (this.filters[key].text==item))
        this.filters[key]={text:"",value:""};        
      else if( !Array.isArray(this.filters[key]) && (this.filters[key]==item))
        this.filters[key]="";           

      else if(Array.isArray(this.filters[key]) && this.filters[key].map(a=>a.text).includes(item))
      this.filters[key]= this.filters[key].filter(a=>a.text!=item);

      else if(Array.isArray(this.filters[key]) && this.filters[key].includes(item))
      this.filters[key]= this.filters[key].filter(a=>a!=item);
      });
      this.filterAddresses(this.filters);
    }, 
    getLookup(column) {
      return this.configurations
        .filter((filter) => filter.LookUpName === column)
        .map((item) => {
          let arr = [];
          arr["text"] =
            this.$vuetify.lang.current === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          arr["value"] = item.LookUpKey;
          return arr;
        });
    },
    async saveAddress() {
      if(this.$refs.form.validate()) {
        if (this.editedIndex == -1) {
          this.createAddress(this.activeAddress).then(() => {
            this.$root.$emit("callAlert",  {text:this.$t("Address Added Successfully"), type:"success", alert:true});
        });
        } else {
          this.updateAddress(this.activeAddress).then(() => {
            this.$root.$emit("callAlert",  {text:this.$t("Address Updated Successfully"), type:"success", alert:true});
        });
        }
        this.close();  
      }
    },
    close() {
      this.dialog = false;
      this.dialog_adv = false;
      this.editedIndex = -1;
      this.activeAddress = {};
    },
    openAdd() {
      this.dialog = true;
      this.editedIndex = -1;
      this.activeAddress = {};
    },
    editItem(item) {
      this.dialog = true;
      this.editedIndex = this.addressesFiltered.indexOf(item);
      this.activeAddress = {};
      Object.assign(this.activeAddress,item);
    },

    async deleteItem(item) {
      const index = this.address.indexOf(item);
      if (
        confirm(this.$t("Are you sure you want to delete this item?")) &&
        this.address.splice(index, 1)
      ) {
        try {
          await axcessApi.deleteRequest("/address/" + item.id);
        } catch (error) {
           this.$root.$emit("callAlert",  {text:this.$t("Something went wrong"), type:"error", alert:true});
        }
      }
    },
    selectAddress(item) {
      this.$emit("selectAddress", item);
    },
    
    ...mapActions("address", ["createAddress","filterAddresses", "updateAddress"]),
  }
};
</script>

<style>
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}

</style>